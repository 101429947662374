import React from "react"
import Container from "../components/container"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Logo from "../components/logo.js"
import Footer from "../components/footer"
import Img from "gatsby-image"

export default function ContentfulBlogpost({ data }) {
  let blogPost = data.contentfulBlogPost

  const imgStyle = {
    borderRadius: "5px",
  }

  const inlineImgContainer = {
    textAlign: "center",
    marginTop: "40px",
  }

  const inlineImg = {
    objectFit: "contain",
    borderRadius: "5px",
    maxWidth: "100%",
  }

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        console.log(node.data.target)
        const url = node.data.target.fixed.src
        return (
          <div style={inlineImgContainer}>
            <img style={inlineImg} src={url} alt="" />
          </div>
        )
      },
    },
  }

  return (
    <Container
      title={blogPost.title}
      article
      image={"https:" + blogPost.image.resize.src}
      description={blogPost.excerpt?.excerpt}
    >
      <Logo />

      <hr />
      <p />
      <div>
        <h1 style={{ display: "inline" }}>{blogPost.title}</h1> -{" "}
        <time itemProp="datePublished" dateTime={blogPost.isoDate} className="credit">
          {blogPost.createdAt}
        </time>
        <p />
        <div>
          <figure>
            <Img
              style={imgStyle}
              id="blogImage"
              fluid={blogPost.image.fluid}
              alt="article"
            />{" "}
            <figcaption>
              {blogPost.image.description
                ? blogPost.image.description
                : "Image from Unsplash"}
            </figcaption>
          </figure>
        </div>
        <article>
          {" "}
          {blogPost.mainContent && renderRichText(blogPost.mainContent, options)}
        </article>
        <hr />
      </div>

      <Footer />
    </Container>
  )
}

export const query = graphql`
    query ($slug: String!) {
        contentfulBlogPost(slug: { eq: $slug }) {
            title
            slug
            excerpt {
                excerpt
            }
            createdAt(formatString: "MMMM D, YYYY")
            isoDate: createdAt
            mainContent {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        fixed(quality: 100) {
                            src
                        }
                    }
                }
            }

            image {
                resize(width: 900, quality: 100) {
                    src
                }

                description
                fluid(maxWidth: 700, maxHeight: 400, quality: 100, resizingBehavior: FILL) {
                    ...GatsbyContentfulFluid_noBase64
                }
            }
        }
    }
`

/* 
     # references {

       # ... on ContentfulBlogPost {
          
        # contentful_id
        #  title
        #  slug
      #  }
      
      #  ... on ContentfulAsset {
          # contentful_id is required to resolve the references
      #    contentful_id
      #    fluid(maxWidth: 600) {
      #      ...GatsbyContentfulFluid_withWebp
      #    }
      #  }
     # }




*/
